import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  newArrFive,
  newArrSix,
} from "../assets/images/index";

import chandlier1 from "../assets/images/products/bestSeller/Chandlier/1.jpeg";
import chandlier2 from "../assets/images/products/bestSeller/Chandlier/2.jpeg";
import chandlier3 from "../assets/images/products/bestSeller/Chandlier/3.jpeg";
import chandlier4 from "../assets/images/products/bestSeller/Chandlier/4.jpeg";
import chandlier5 from "../assets/images/products/bestSeller/Chandlier/5.jpeg";
import chandlier6 from "../assets/images/products/bestSeller/Chandlier/6.jpeg";
import chandlier7 from "../assets/images/products/bestSeller/Chandlier/7.jpeg";
import chandlier8 from "../assets/images/products/bestSeller/Chandlier/8.jpeg";
import chandlier10 from "../assets/images/products/bestSeller/Chandlier/10.jpeg";
import chandlier11 from "../assets/images/products/bestSeller/Chandlier/11.jpeg";
import chandlier12 from "../assets/images/products/bestSeller/Chandlier/12.jpeg";
import chandlier13 from "../assets/images/products/bestSeller/Chandlier/13.jpeg";
import chandlier14 from "../assets/images/products/bestSeller/Chandlier/14.jpeg";
import l1 from "../assets/images/products/bestSeller/Lighting lamp/l1.jpg";
import l2 from "../assets/images/products/bestSeller/Lighting lamp/l2.jpg";
import l3 from "../assets/images/products/bestSeller/Lighting lamp/l3.jpg";
import l4 from "../assets/images/products/bestSeller/Lighting lamp/l4.jpg";
import l5 from "../assets/images/products/bestSeller/Lighting lamp/l5.jpg";
import l6 from "../assets/images/products/bestSeller/Lighting lamp/l6.jpg";
import l7 from "../assets/images/products/bestSeller/Lighting lamp/l7.jpg";
import l8 from "../assets/images/products/bestSeller/Lighting lamp/l8.jpg";
import l9 from "../assets/images/products/bestSeller/Lighting lamp/l9.jpg";
import l10 from "../assets/images/products/bestSeller/Lighting lamp/l10.jpg";
import l11 from "../assets/images/products/bestSeller/Lighting lamp/l11.jpg";
import l12 from "../assets/images/products/bestSeller/Lighting lamp/l12.jpg";
import l13 from "../assets/images/products/bestSeller/Lighting lamp/l13.jpg";
import l14 from "../assets/images/products/bestSeller/Lighting lamp/l14.jpg";
import l15 from "../assets/images/products/bestSeller/Lighting lamp/l15.jpg";
import l16 from "../assets/images/products/bestSeller/Lighting lamp/l16.jpg";
import Tp1 from "../assets/images/products/bestSeller/Center price/Tp1.jpg";
import Tp2 from "../assets/images/products/bestSeller/Center price/Tp2.jpg";
import Tp3 from "../assets/images/products/bestSeller/Center price/Tp3.jpg";
import Tp4 from "../assets/images/products/bestSeller/Center price/Tp4.jpg";
import Tp5 from "../assets/images/products/bestSeller/Center price/Tp5.jpg";
import Tp6 from "../assets/images/products/bestSeller/Center price/Tp6.jpg";
import Tp7 from "../assets/images/products/bestSeller/Center price/Tp7.jpg";
import Tp8 from "../assets/images/products/bestSeller/Center price/Tp8.jpg";
import Tp9 from "../assets/images/products/bestSeller/Center price/Tp9.jpg";
import Tp10 from "../assets/images/products/bestSeller/Center price/Tp10.jpg";
import Tp11 from "../assets/images/products/bestSeller/Center price/Tp11.jpg";
import Tp12 from "../assets/images/products/bestSeller/Center price/Tp12.jpg";
import Mp1 from "../assets/images/products/bestSeller/Metal props/Mp1.jpg";
import Mp2 from "../assets/images/products/bestSeller/Metal props/Mp2.jpg";
import Mp3 from "../assets/images/products/bestSeller/Metal props/Mp3.jpg";
import Mp4 from "../assets/images/products/bestSeller/Metal props/Mp4.jpg";
import Mp5 from "../assets/images/products/bestSeller/Metal props/Mp5.jpg";
import Mp6 from "../assets/images/products/bestSeller/Metal props/Mp6.jpg";
import Mp7 from "../assets/images/products/bestSeller/Metal props/Mp7.jpg";
import Mp8 from "../assets/images/products/bestSeller/Metal props/Mp8.jpg";
import Mp9 from "../assets/images/products/bestSeller/Metal props/Mp9.jpg";
import Mp10 from "../assets/images/products/bestSeller/Metal props/Mp10.jpg";
import Mp11 from "../assets/images/products/bestSeller/Metal props/Mp11.jpg";
import Mp12 from "../assets/images/products/bestSeller/Metal props/Mp12.jpg";
import Mp13 from "../assets/images/products/bestSeller/Metal props/Mp13.jpg";
import Mp14 from "../assets/images/products/bestSeller/Metal props/Mp14.jpg";
import Mp15 from "../assets/images/products/bestSeller/Metal props/Mp15.jpg";
import Wp1 from "../assets/images/products/bestSeller/Wodden props/Wp1.jpg";
import Wp2 from "../assets/images/products/bestSeller/Wodden props/Wp2.jpg";
import Wp3 from "../assets/images/products/bestSeller/Wodden props/Wp3.jpg";
import Wp4 from "../assets/images/products/bestSeller/Wodden props/Wp4.jpg";
import Wp5 from "../assets/images/products/bestSeller/Wodden props/Wp5.jpg";
import Wp6 from "../assets/images/products/bestSeller/Wodden props/Wp6.jpg";
import Wp7 from "../assets/images/products/bestSeller/Wodden props/Wp7.jpg";
import Wp8 from "../assets/images/products/bestSeller/Wodden props/Wp8.jpg";
import Wp9 from "../assets/images/products/bestSeller/Wodden props/Wp9.jpg";
import Wp10 from "../assets/images/products/bestSeller/Wodden props/Wp10.jpg";
import Wp11 from "../assets/images/products/bestSeller/Wodden props/Wp11.jpg";
import Wp12 from "../assets/images/products/bestSeller/Wodden props/Wp12.jpg";
import Wp13 from "../assets/images/products/bestSeller/Wodden props/Wp13.jpg";
import Gp1 from "../assets/images/products/bestSeller/Glass props/Gp1.jpg";
import Gp2 from "../assets/images/products/bestSeller/Glass props/Gp2.jpg";
import Gp3 from "../assets/images/products/bestSeller/Glass props/Gp3.jpg";
import Gp4 from "../assets/images/products/bestSeller/Glass props/Gp4.jpg";
import Gp5 from "../assets/images/products/bestSeller/Glass props/Gp5.jpg";
import Gp6 from "../assets/images/products/bestSeller/Glass props/Gp6.jpg";
import Gp7 from "../assets/images/products/bestSeller/Glass props/Gp7.jpg";
import Gp8 from "../assets/images/products/bestSeller/Glass props/Gp8.jpg";
import Gp9 from "../assets/images/products/bestSeller/Glass props/Gp9.jpg";
import Gp10 from "../assets/images/products/bestSeller/Glass props/Gp10.jpg";
import Gp11 from "../assets/images/products/bestSeller/Glass props/Gp11.jpg";
import Gp12 from "../assets/images/products/bestSeller/Glass props/Gp12.jpg";
import Gp13 from "../assets/images/products/bestSeller/Glass props/Gp13.jpg";
import Gp14 from "../assets/images/products/bestSeller/Glass props/Gp14.jpg";
import Hm1 from "../assets/images/products/bestSeller/Haldi mehdi/Hm1.jpg";
import Hm2 from "../assets/images/products/bestSeller/Haldi mehdi/Hm2.jpg";
import Hm3 from "../assets/images/products/bestSeller/Haldi mehdi/Hm3.jpg";
import Hm4 from "../assets/images/products/bestSeller/Haldi mehdi/Hm4.jpg";
import Hm5 from "../assets/images/products/bestSeller/Haldi mehdi/Hm5.jpg";
import Hm6 from "../assets/images/products/bestSeller/Haldi mehdi/Hm6.jpg";
import Hm7 from "../assets/images/products/bestSeller/Haldi mehdi/Hm7.jpg";
import Hm8 from "../assets/images/products/bestSeller/Haldi mehdi/Hm8.jpg";
import Hm9 from "../assets/images/products/bestSeller/Haldi mehdi/Hm9.jpg";
import Hm10 from "../assets/images/products/bestSeller/Haldi mehdi/Hm10.jpg";
import Hm11 from "../assets/images/products/bestSeller/Haldi mehdi/Hm11.jpg";
import Hm12 from "../assets/images/products/bestSeller/Haldi mehdi/Hm12.jpg";
import Hm13 from "../assets/images/products/bestSeller/Haldi mehdi/Hm13.jpg";
import Hm14 from "../assets/images/products/bestSeller/Haldi mehdi/Hm14.jpg";
import Hm15 from "../assets/images/products/bestSeller/Haldi mehdi/Hm15.jpg";
import Hm16 from "../assets/images/products/bestSeller/Haldi mehdi/Hm16.jpg";
import Hm17 from "../assets/images/products/bestSeller/Haldi mehdi/hm17.jpg";
import Hm18 from "../assets/images/products/bestSeller/Haldi mehdi/hm18.jpg";
import Lt1 from "../assets/images/products/bestSeller/Laxury theme/Lt1.jpg";
import Lt2 from "../assets/images/products/bestSeller/Laxury theme/Lt2.jpg";
import ElephantImg from "../assets/images/products/RecentProduct/RecentProductImage.webp";
import Mr1 from "../assets/images/products/bestSeller/Mirror props/Mr1.jpg";
import Mr2 from "../assets/images/products/bestSeller/Mirror props/Mr2.jpg";
import Mr3 from "../assets/images/products/bestSeller/Mirror props/Mr3.jpg";
import Mr4 from "../assets/images/products/bestSeller/Mirror props/Mr4.jpg";
import Mr5 from "../assets/images/products/bestSeller/Mirror props/Mr5.jpg";
import Mr6 from "../assets/images/products/bestSeller/Mirror props/Mr6.jpg";
import Mr7 from "../assets/images/products/bestSeller/Mirror props/Mr7.jpg";
import Mr8 from "../assets/images/products/bestSeller/Mirror props/Mr8.jpg";
import Mr9 from "../assets/images/products/bestSeller/Mirror props/Mr9.jpg";
import Mr10 from "../assets/images/products/bestSeller/Mirror props/Mr10.jpg";
import Mr11 from "../assets/images/products/bestSeller/Mirror props/Mr11.jpg";
import Mr12 from "../assets/images/products/bestSeller/Mirror props/Mr12.jpg";
import Mr13 from "../assets/images/products/bestSeller/Mirror props/Mr13.jpg";
import RecentProductImageOne from '../assets/images/New Product Images/RecentProductImageOne.webp';
import RecentProductImageTwo from '../assets/images/New Product Images/RecentProductImageTwo.webp';
import RecentProductImageThree from '../assets/images/New Product Images/RecentProductImageThree.webp';
import RecentProductImageFour from '../assets/images/New Product Images/RecentProductImageFour.webp';
// IDs to be categorized as "Stand"
const standIds = [
  "201", "1001", "1004", "1008", "1009", "1018", "1020", 
  "1024", "1032", "1034", "1040", "1041", "1042", "1046"
];

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  {
    _id: 1005,
    title: "Journal",
    link: "/journal",
  },
];
// =================== NavBarList End here ======================

// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
     img: spfOne,
     productName: "Led Flower Stand",
    price: "6000.00",
    color: "Size: 24/36/48",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: standIds.includes("201") ? "Stand" : "Others",
  },
  {
    _id: "202",
    img: newArrFour,
    productName: "Acrylic Chandelier",
    price: "2600.00",
    color: "Size: 36 inch",
    badge: true,
    des: "An elegant acrylic chandelier that radiates light beautifully, adding a modern and sophisticated touch to any room.",
    category: "Others",
  },
  {
    _id: "203",
    img: spfThree,
    productName: "Flower Gallery",
    price: "2399.00",
    color: "Size: 24 inch",
    badge: true,
    des: "Explore our Flower Gallery a vibrant collection of fresh blooms and unique arrangements perfect for any occasion.",
    category: "Others",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  // {
  //   _id: "1001",
  //   img: spfOne,
  //   productName: "Led Flower Stand",
  //   price: "6000.00",
  //   color: "Size: 24/36/48",
  //   badge: true,
  //   des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
  //   category: standIds.includes("1001") ? "Stand" : "Others",
  // },
  // {
  //   _id: "1002",
  //   img: spfTwo,
  //   productName: "Acrylic Chandelier",
  //   price: "2600.00",
  //   color: "Size: 36 inch",
  //   badge: true,
  //   des: "An elegant acrylic chandelier that radiates light beautifully, adding a modern and sophisticated touch to any room.",
  //   category: "Others",
  // },
  // {
  //   _id: "1003",
  //   img: spfThree,
  //   productName: "Flower Gallery",
  //   price: "2399.00",
  //   color: "Size: 24 inch",
  //   badge: true,
  //   des: "Explore our Flower Gallery a vibrant collection of fresh blooms and unique arrangements perfect for any occasion.",
  //   category: "Others",
  // },
  {
    _id: "1004",
    img: ElephantImg,
    productName: "Elephant Stand",
    price: "14500.00",
    color: "Size: 5 feet",
    badge: true,
    des: "Elephant Stand a durable and decorative support platform inspired by the strength and elegance of elephants, ideal for showcasing plants, sculptures, or decorative items.",
    category: standIds.includes("1004") ? "Stand" : "Others",
  },
  {
    _id: "1005",
    img: RecentProductImageOne,
    productName: "Deer",
    price: "9000.00",
    color: "Size: 7 feet",
    badge: true,
    des: "Deer a graceful and intricate piece capturing the elegance and serenity of a deer, perfect for adding a touch of nature-inspired art to any space.",
    category: "Others",
  },
  {
    _id: "1006",
    img: RecentProductImageTwo,
    productName: "Crystal Tree",
    price: "16500.00",
    color: "Size: 10 feet",
    badge: false,
    des: "Crystal Tree a shimmering and artistic display, featuring crystal branches that reflect light beautifully, bringing elegance and positive energy into any room.",
    category: "Others",
  },
  {
    _id: "1007",
    img: RecentProductImageFour,
    productName: "Crystal Hanging",
    price: "600.00",
    color: "Size: 14 inch",
    badge: true,
    des: "Crystal Hanging a captivating decorative piece with sparkling crystal elements, designed to catch and reflect light beautifully, adding elegance and charm to any space.",
    category: "Others",
  },
  {
    _id: "1008",
    img: RecentProductImageThree,
    productName: "Lump Stand",
    price: "1150.00",
    color: "Size: 14/18/22 inch",
    badge: false,
    des: "Lump Stand a sturdy and stylish base designed to support various Lightings styles, adding both function and flair to any lighting setup.",
    category: standIds.includes("1008") ? "Stand" : "Others",
  },
  // {
  //   _id: "1009",
  //   img: newArrOne,
  //   productName: "Round Table Stand",
  //   price: "3200.00",
  //   color: "Size: 42 inch",
  //   badge: true,
  //   des: "A sleek round table stand, perfect for holding decor or essentials, adding a touch of modern elegance to any space.",
  //   category: standIds.includes("1009") ? "Stand" : "Others",
  // },
  // {
  //   _id: "1012",
  //   img: newArrFour,
  //   productName: "Acrylic Chandelier",
  //   price: "2600.00",
  //   color: "Size: 36 inch",
  //   badge: false,
  //   des: "An elegant acrylic chandelier that radiates light beautifully, adding a modern and sophisticated touch to any room.",
  //   category: "Others",
  // },
  // {
  //   _id: "1013",
  //   img: newArrTwo,
  //   productName: "Funny toys for babies",
  //   price: "60.00",
  //   color: "Mixed",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1014",
  //   img: newArrTwo,
  //   productName: "Crystal Stand",
  //   price: "2000.00",
  //   color: "Size: 32 inch",
  //   badge: true,
  //   des: "A stunning crystal stand that enhances the beauty of any display, bringing a luxurious sparkle to your decor.",
  //   category: "Others",
  // },
  {
    _id: "1015",
    img: newArrFour,
    productName: "Acrylic Chandelier",
    price: "2600.00",
    color: "Size: 36 inch",
    badge: false,
    des: "An elegant acrylic chandelier that radiates light beautifully, adding a modern and sophisticated touch to any room.",
    category: "Others",
  },
  // {
  //   _id: "1016",
  //   img: newArrTwo,
  //   productName: "Crystal Stand",
  //   price: "2000.00",
  //   color: "Size: 32 inch",
  //   badge: true,
  //   des: "A stunning crystal stand that enhances the beauty of any display, bringing a luxurious sparkle to your decor.",
  //   category: "Others",
  // },
  // {
  //   _id: "1017",
  //   img: bestSellerFour,
  //   productName: "Travel Bag",
  //   price: "220.00",
  //   color: "Black",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1018",
  //   img: newArrOne,
  //   productName: "Round Table Stand",
  //   price: "3200.00",
  //   color: "Size: 42 inch",
  //   badge: true,
  //   des: "A sleek round table stand, perfect for holding decor or essentials, adding a touch of modern elegance to any space.",
  //   category: standIds.includes("1018") ? "Stand" : "Others",
  // },
  // {
  //   _id: "1019",
  //   img: newArrTwo,
  //   productName: "Crystal Stand",
  //   price: "2000.00",
  //   color: "Size: 32 inch",
  //   badge: true,
  //   des: "A stunning crystal stand that enhances the beauty of any display, bringing a luxurious sparkle to your decor.",
  //   category: "Others",
  // },
  // {
  //   _id: "1020",
  //   img: newArrThree,
  //   productName: "Acrylic Stand",
  //   price: "2400.00",
  //   color: "Size: 48 inch",
  //   badge: true,
  //   des: "A clear acrylic stand that stylishly showcases items with a modern, minimalist touch, perfect for any setting.",
  //   category: standIds.includes("1020") ? "Stand" : "Others",
  // },
  // {
  //   _id: "1021",
  //   img: spfThree,
  //   productName: "Flower Gallery",
  //   price: "2399.00",
  //   color: "Size: 24 inch",
  //   badge: true,
  //   des: "Explore our Flower Gallery a vibrant collection of fresh blooms and unique arrangements perfect for any occasion.",
  //   category: "Others",
  // },
  // {
  //   _id: "1022",
  //   img: spfFour,
  //   productName: "Sun glasses",
  //   price: "220.00",
  //   color: "Black",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1023",
  //   img: bestSellerOne,
  //   productName: "Flower Base",
  //   price: "35.00",
  //   color: "Blank and White",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  {
    _id: "1024",
    img: spfOne,
    productName: "Led Flower Stand",
    price: "6000.00",
    color: "Size: 24/36/48",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: standIds.includes("1024") ? "Stand" : "Others",
  },
  {
    _id: "1025",
    img: spfTwo,
    productName: "Acrylic Chandelier",
    price: "2600.00",
    color: "Size: 36 inch",
    badge: true,
    des: "An elegant acrylic chandelier that radiates light beautifully, adding a modern and sophisticated touch to any room.",
    category: "Others",
  },
  // {
  //   _id: "1026",
  //   img: spfThree,
  //   productName: "Flower Gallery",
  //   price: "2399.00",
  //   color: "Size: 24 inch",
  //   badge: true,
  //   des: "Explore our Flower Gallery a vibrant collection of fresh blooms and unique arrangements perfect for any occasion.",
  //   category: "Others",
  // },
  // {
  //   _id: "1027",
  //   img: spfFour,
  //   productName: "Sun glasses",
  //   price: "220.00",
  //   color: "Black",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1028",
  //   img: bestSellerOne,
  //   productName: "Flower Base",
  //   price: "35.00",
  //   color: "Blank and White",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1029",
  //   img: bestSellerTwo,
  //   productName: "New Backpack",
  //   price: "180.00",
  //   color: "Gray",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1030",
  //   img: bestSellerThree,
  //   productName: "Household materials",
  //   price: "25.00",
  //   color: "Mixed",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1031",
  //   img: bestSellerFour,
  //   productName: "Travel Bag",
  //   price: "220.00",
  //   color: "Black",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1032",
  //   img: newArrOne,
  //   productName: "Round Table Stand",
  //   price: "3200.00",
  //   color: "Size: 42 inch",
  //   badge: true,
  //   des: "A sleek round table stand, perfect for holding decor or essentials, adding a touch of modern elegance to any space.",
  //   category: standIds.includes("1032") ? "Stand" : "Others",
  // },
  // {
  //   _id: "1033",
  //   img: newArrTwo,
  //   productName: "Crystal Stand",
  //   price: "2000.00",
  //   color: "Size: 32 inch",
  //   badge: true,
  //   des: "A stunning crystal stand that enhances the beauty of any display, bringing a luxurious sparkle to your decor.",
  //   category: "Others",
  // },
  // {
  //   _id: "1034",
  //   img: newArrThree,
  //   productName: "Acrylic Stand",
  //   price: "2400.00",
  //   color: "Size: 48 inch",
  //   badge: true,
  //   des: "A clear acrylic stand that stylishly showcases items with a modern, minimalist touch, perfect for any setting.",
  //   category: standIds.includes("1034") ? "Stand" : "Others",
  // },
  // {
  //   _id: "1035",
  //   img: newArrFour,
  //   productName: "Funny toys for babies",
  //   price: "60.00",
  //   color: "Mixed",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1036",
  //   img: newArrTwo,
  //   productName: "Funny toys for babies",
  //   price: "60.00",
  //   color: "Mixed",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1037",
  //   img: newArrFour,
  //   productName: "Funny toys for babies",
  //   price: "60.00",
  //   color: "Mixed",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  // {
  //   _id: "1038",
  //   img: newArrTwo,
  //   productName: "Crystal Stand",
  //   price: "2000.00",
  //   color: "Size: 32 inch",
  //   badge: true,
  //   des: "A stunning crystal stand that enhances the beauty of any display, bringing a luxurious sparkle to your decor.",
  //   category: "Others",
  // },
  // {
  //   _id: "1039",
  //   img: bestSellerFour,
  //   productName: "Travel Bag",
  //   price: "220.00",
  //   color: "Black",
  //   badge: false,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   category: "Others",
  // },
  {
    _id: "1040",
    img: newArrOne,
    productName: "Round Table Stand",
    price: "3200.00",
    color: "Size: 42 inch",
    badge: true,
    des: "A sleek round table stand, perfect for holding decor or essentials, adding a touch of modern elegance to any space.",
    category: standIds.includes("1040") ? "Stand" : "Others",
  },
  {
    _id: "1041",
    img: newArrTwo,
    productName: "Crystal Stand",
    price: "2000.00",
    color: "Size: 32 inch",
    badge: true,
    des: "A stunning crystal stand that enhances the beauty of any display, bringing a luxurious sparkle to your decor.",
    category: standIds.includes("1041") ? "Stand" : "Others",
  },
  {
    _id: "1042",
    img: newArrThree,
    productName: "Acrylic Stand",
    price: "2400.00",
    color: "Size: 48 inch",
    badge: true,
    des: "A clear acrylic stand that stylishly showcases items with a modern, minimalist touch, perfect for any setting.",
    category: standIds.includes("1042") ? "Stand" : "Others",
  },
  {
    _id: "1043",
    img: spfThree,
    productName: "Flower Gallery",
    price: "2399.00",
    color: "Size: 24 inch",
    badge: true,
    des: "Explore our Flower Gallery a vibrant collection of fresh blooms and unique arrangements perfect for any occasion.",
    category: "Others",
  },
  {
    _id: 100005,
    img: newArrFive,
    productName: "Table Crystal Stand‎ ‎  ",
    price: "2400.00",
    color: "Size: 32 inch",
    badge: false,
    des: "Enhance your décor with our Table Crystal Stand a stunning centerpiece that combines elegance with functionality.",
    category: "Stand",
   },
   {
    _id: 100006,
    img: newArrSix,
    productName: "Lotus Lamp",
    price: "2700.00",
    color: "Size: 30 inch",
    badge: false,
    des: "Illuminate your space with our Lotus Lamp, a serene design inspired by nature, bringing warmth and tranquility to any room.",
    category: "Lightings",
 },
 
  {
    _id: "1044",
    img: chandlier1,
    productName: "Md01",
    price: "3400.00",
    color: "Size: 24/42 inch",
    badge: true,
    des: "Elevate your event with the timeless elegance of our stunning chandeliers at MD Wedding Props. Perfect for creating a dazzling ambiance that captivates.",
    category: "Chandelier",
  },
  {
    _id: "1045",
    img: chandlier2,
    productName: "Md02",
    price: "3400.00",
    color: "Size: 24/42 Inch",
    badge: true,
    des: "Elevate your event with the timeless elegance of our stunning chandeliers at MD Wedding Props. Perfect for creating a dazzling ambiance that captivates.",
    category: "Chandelier",
  },
  {
    _id: "1046",
    img: chandlier3,
    productName: "Md04",
    price: "12500.00",
    color: "Size: 24/42 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1047",
    img: chandlier4,
    productName: "Md05",
    price: "2999.00",
    color: "Size: 20/28 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1048",
    img: chandlier5,
    productName: "Md05",
    price: "3650.00",
    color: "Size: 24/46 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1049",
    img: chandlier6,
    productName: "Md06",
    price: "4200.00",
    color: "Size: 24/42 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1050",
    img: chandlier7,
    productName: "Md07",
    price: "2100.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1051",
    img: chandlier8,
    productName: "Md08",
    price: "5499.00",
    color: "Size: 24/48 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1053",
    img: chandlier10,
    productName: "Md10",
    price: "2400.00",
    color: "Size: 24/42 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1054",
    img: chandlier11,
    productName: "Md11",
    price: "1199.00",
    color: "Size: 14 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1055",
    img: chandlier12,
    productName: "Md12",
    price: "1650.00",
    color: "Size: 30 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1056",
    img: chandlier13,
    productName: "Md12",
    price: "1599.00",
    color: "Size: 15 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1057",
    img: chandlier14,
    productName: "Md12",
    price: "3450.00",
    color: "Size: 24 Inch",
    badge: true,
    des: "A stylish LED flower stand that illuminates and showcases your plants, creating a vibrant and elegant indoor display.",
    category: "Chandelier",
  },
  {
    _id: "1058",
    img: l1,
    productName: "Lump001",
    price: "550.00",
    color: "Size: 12 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1059",
    img: l2,
    productName: "Lump002",
    price: "900.00",
    color: "Size: 24 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1060",
    img: l3,
    productName: "Lump003",
    price: "499.00",
    color: "Size: 14 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1061",
    img: l4,
    productName: "Lump004",
    price: "1199.00",
    color: "Size: 24 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1062",
    img: l5,
    productName: "Lump005",
    price: "750.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1063",
    img: l6,
    productName: "Lump006",
    price: "499.00",
    color: "Size: 16 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1066",
    img: l7,
    productName: "Lump007",
    price: "450.00",
    color: "Size: 14 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1067",
    img: l8,
    productName: "Lump08",
    price: "650.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1068",
    img: l9,
    productName: "Lump009",
    price: "1200.00",
    color: "Size: 24 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1069",
    img: l10,
    productName: "Lump0010",
    price: "450.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1070",
    img: l11,
    productName: "Lump0011",
    price: "600.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1071",
    img: l12,
    productName: "Lump0012",
    price: "420.00",
    color: "Size: 16 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1072",
    img: l13,
    productName: "Lump0013",
    price: "1100.00",
    color: "Size: 12/18/22 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1073",
    img: l14,
    productName: "Lump0014",
    price: "450.00",
    color: "Size: 12 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1074",
    img: l15,
    productName: "Lump0015",
    price: "12000.00",
    color: "Size: 12/24/36/48/60 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1075",
    img: l16,
    productName: "Lump0016",
    price: "7500.00",
    color: "Size: 12/18/24/30/36 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Lighting lamp",
  },
  {
    _id: "1077",
    img: Tp1,
    productName: "tableprops1",
    price: "6500.00",
    color: "Size: 48 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1078",
    img: Tp2,
    productName: "tableprops2",
    price: "4900.00",
    color: "Size: 48 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1079",
    img: Tp3,
    productName: "tableprops3",
    price: "2400.00",
    color: "Size: 32 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1080",
    img: Tp4,
    productName: "tableprops4",
    price: "2100.00",
    color: "Size: 32 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1081",
    img: Tp5,
    productName: "tableprops5",
    price: "2100.00",
    color: "Size: 18/32 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1082",
    img: Tp6,
    productName: "tableprops6",
    price: "3200.00",
    color: "Size: 36 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1083",
    img: Tp7,
    productName: "tableprops7",
    price: "4200.00",
    color: "Size: 42 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1084",
    img: Tp8,
    productName: "tableprops8",
    price: "1800.00",
    color: "Size: 36 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1085",
    img: Tp9,
    productName: "tableprops9",
    price: "5600.00",
    color: "Size: 18/24/30 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1086",
    img: Tp10,
    productName: "tableprops10",
    price: "3200.00",
    color: "Size: 36 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1087",
    img: Tp11,
    productName: "tableprops11",
    price: "2200.00",
    color: "Size: 42 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1088",
    img: Tp12,
    productName: "tableprops12",
    price: "2200.00",
    color: "Size: 42 Inch",
    badge: true,
    des: "Lighting a lamp signifies the dispelling of darkness and the beginning of a new journey with hope, positivity, and enlightenment.",
    category: "Center piece",
  },
  {
    _id: "1089",
    img: Mp1,
    productName: "Metalprops01",
    price: "3200.00",
    color: "Size: 6 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1090",
    img: Mp2,
    productName: "Metalprops02",
    price: "6200.00",
    color: "Size: 8 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1091",
    img: Mp3,
    productName: "Metalprops03",
    price: "7500.00",
    color: "Size: 7/6 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1092",
    img: Mp4,
    productName: "Metalprops04",
    price: "22000.00",
    color: "Size: 10/24 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1093",
    img: Mp5,
    productName: "Metalprops05",
    price: "4800.00",
    color: "Size: 7 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1094",
    img: Mp6,
    productName: "Metalprops06",
    price: "3200.00",
    color: "Size: 5 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1095",
    img: Mp7,
    productName: "Metalprops07",
    price: "9500.00",
    color: "Size: 12/6 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1096",
    img: Mp8,
    productName: "Metalprops08",
    price: "2900.00",
    color: "Size: 5 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1097",
    img: Mp9,
    productName: "Metalprops09",
    price: "1800.00/2400.00/2800.00",
    color: "Size: 4/5/6 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1098",
    img: Mp10,
    productName: "Metalprops010",
    price: "3900.00",
    color: "Size: 7 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1099",
    img: Mp11,
    productName: "Metalprops011",
    price: "3600.00",
    color: "Size: 6 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1100",
    img: Mp12,
    productName: "Metalprops012",
    price: "12500.00",
    color: "Size: 8/5/3 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1101",
    img: Mp13,
    productName: "Metalprops013",
    price: "2400.00",
    color: "Size: 2/2 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1102",
    img: Mp14,
    productName: "Metalprops014",
    price: "2600.00",
    color: "Size: 5 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1103",
    img: Mp15,
    productName: "Metalprops015",
    price: "2400.00",
    color: "Size: 4 Feet",
    badge: true,
    des: "Metal props are durable, realistic decorative items used for enhancing stage or event displays with an industrial or metallic aesthetic.",
    category: "Metal props",
  },
  {
    _id: "1104",
    img: Wp1,
    productName: "WoodpropsW01",
    price: "6200.00",
    color: "Size: 4/7 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1105",
    img: Wp2,
    productName: "WoodpropsW02",
    price: "6200.00",
    color: "Size: 4/7 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1106",
    img: Wp3,
    productName: "WoodpropsW03",
    price: "4800.00",
    color: "Size: 18/24/30 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1107",
    img: Wp4,
    productName: "WoodpropsW04",
    price: "4200.00",
    color: "Size: 4 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1108",
    img: Wp5,
    productName: "WoodpropsW05",
    price: "2600.00",
    color: "Size: 4 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1109",
    img: Wp6,
    productName: "WoodpropsW06",
    price: "7200.00",
    color: "Size: 30/36/42 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1110",
    img: Wp7,
    productName: "WoodpropsW07",
    price: "2100.00",
    color: "Size: 3 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1111",
    img: Wp8,
    productName: "WoodpropsW08",
    price: "2700.00",
    color: "Size: 30 Inch",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1112",
    img: Wp9,
    productName: "WoodpropsW09",
    price: "750.00",
    color: "Size: 20 Inch",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1113",
    img: Wp10,
    productName: "WoodpropsW010",
    price: "270.00",
    color: "Size: 12 Inch",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1114",
    img: Wp11,
    productName: "WoodpropsW011",
    price: "7500.00/900.00",
    color: "Size: 20/24 Inch",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1115",
    img: Wp12,
    productName: "WoodpropsW012",
    price: "550.00/700.00/850.00",
    color: "Size: 16/20/24 Inch",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1116",
    img: Wp13,
    productName: "WoodpropsW013",
    price: "5200.00",
    color: "Size: 3 Feet",
    badge: true,
    des: "A centre table serves as a focal point in a living space, providing both functionality and style for displaying items or holding essentials.",
    category: "Wooden Products",
  },
  {
    _id: "1117",
    img: Gp1,
    productName: "Glass propsG01",
    price: "1600.00",
    color: "Size: 18/24/28 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1118",
    img: Gp2,
    productName: "Glass propsG02",
    price: "2700.00",
    color: "Size: 48 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1119",
    img: Gp3,
    productName: "Glass propsG03",
    price: "1700.00",
    color: "Size: 6/10/14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1120",
    img: Gp4,
    productName: "Glass propsG04",
    price: "950.00",
    color: "Size: 8/10/12 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1121",
    img: Gp5,
    productName: "Glass propsG05",
    price: "480.00",
    color: "Size: 42 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1122",
    img: Gp6,
    productName: "Glass propsG06",
    price: "5400.00",
    color: "Size: 42 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },

  {
    _id: "1123",
    img: Gp7,
    productName: "Glass propsG07",
    price: "290.00",
    color: "Size: 6 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1124",
    img: Gp8,
    productName: "Glass propsG08",
    price: "420.00",
    color: "Size: 14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1125",
    img: Gp9,
    productName: "Glass propsG09",
    price: "450.00",
    color: "Size: 14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1126",
    img: Gp10,
    productName: "Glass propsG010",
    price: "650.00",
    color: "Size: 16 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1127",
    img: Gp11,
    productName: "Glass propsG011",
    price: "950.00",
    color: "Size: 36 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1128",
    img: Gp12,
    productName: "Glass propsG012",
    price: "3600.00",
    color: "Size: 18/24/30 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1129",
    img: Gp13,
    productName: "Glass propsG013",
    price: "2100.00",
    color: "Size: 36 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1130",
    img: Gp14,
    productName: "Glass propsG014",
    price: "2700.00",
    color: "Size: 48 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Glass Products",
  },
  {
    _id: "1131",
    img: Hm1,
    productName: "Urli01",
    price: "6500.00",
    color: "Size: 12/14/16 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1132",
    img: Hm2,
    productName: "planter02",
    price: "7500.00",
    color: "Size: 10/12/14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1133",
    img: Hm3,
    productName: "planter03",
    price: "4500.00",
    color: "Size: 12/14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1134",
    img: Hm4,
    productName: "Candal stand03 ",
    price: "1600.00",
    color: "Size: 12/14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1135",
    img: Hm5,
    productName: "Bottle04",
    price: "1500.00",
    color: "Size: 14 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1136",
    img: Hm6,
    productName: "Pot05",
    price: "2100.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1137",
    img: Hm7,
    productName: "Bottle06",
    price: "1400.00",
    color: "Size: 15 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1138",
    img: Hm8,
    productName: "Panter07",
    price: "2700.00",
    color: "Size: 8/10/12 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1139",
    img: Hm9,
    productName: "Aluminium pot07",
    price: "3200.00",
    color: "Size: 14/18/24 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1140",
    img: Hm10,
    productName: "Abra08",
    price: "5500.00",
    color: "Size: 48 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1141",
    img: Hm11,
    productName: "Lotus stand09",
    price: "2900.00",
    color: "Size: 24/30/36 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1142",
    img: Hm12,
    productName: "Kalas010",
    price: "2900.00",
    color: "Size: 36/48/60 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1143",
    img: Hm13,
    productName: "Kalas bowls011",
    price: "9500.00",
    color: "Size: 36/48/60 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1144",
    img: Hm14,
    productName: " Aluminium kalas012",
    price: "13500.00",
    color: "Size: 36/48/60 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1145",
    img: Hm15,
    productName: "Abra pot013",
    price: "2100.00",
    color: "Size: 14/18/24 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1146",
    img: Hm16,
    productName: "Ambus candal014",
    price: "4800.00",
    color: "Size: 18/24/30 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1147",
    img: Hm17,
    productName: "Elephant015",
    price: "1500.00",
    color: "Size: 4/6/8 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1148",
    img: Hm18,
    productName: "Candal abra016",
    price: "2700.00",
    color: "Size: 36 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Haldi mehdi",
  },
  {
    _id: "1149",
    img: Lt1,
    productName: "Crystal stage01",
    price: "210000.00",
    color: "Size: 10/30 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Luxury theme",
  },
  {
    _id: "1150",
    img: Lt2,
    productName: "Crystal pillar",
    price: "21000.00",
    color: "Size: 3/9 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Luxury theme",
  },
  {
    _id: "1151",
    img: Mr1,
    productName: "Horse01",
    price: "56000.00",
    color: "Size: 10 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1152",
    img: Mr2,
    productName: "Horse02",
    price: "52000.00",
    color: "Size: 10 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1153",
    img: Mr3,
    productName: "Cow03",
    price: "45000.00",
    color: "Size: 9 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1154",
    img: Mr4,
    productName: "Giraffee04",
    price: "42000.00",
    color: "Size: 11 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1155",
    img: Mr5,
    productName: "Elephant05",
    price: "28000.00",
    color: "Size: 5 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1156",
    img: Mr6,
    productName: "Pot06",
    price: "9200.00",
    color: "Size: 2/3/4 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1157",
    img: Mr7,
    productName: "Table top07",
    price: "1050.00",
    color: "Size: 16 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1158",
    img: Mr8,
    productName: "Table top08",
    price: "1100.00",
    color: "Size: 18 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1159",
    img: Mr9,
    productName: "Bottlee09",
    price: "2100.00",
    color: "Size: 24 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1160",
    img: Mr10,
    productName: "Panal010",
    price: "9600.00",
    color: "Size: 4/8 Inch",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1161",
    img: Mr11,
    productName: "Table011",
    price: "8000.00",
    color: "Size: 4 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1162",
    img: Mr12,
    productName: "Stage012",
    price: "Enquiry",
    color: "Customize size",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
  {
    _id: "1163",
    img: Mr13,
    productName: "Table013",
    price: "8000.00",
    color: "Size: 4 Feet",
    badge: true,
    des: "Glass products are versatile items crafted from glass, offering elegance and utility in various forms such as décor, tableware, and functional pieces.",
    category: "Mirror props",
  },
];
// =================== PaginationItems End here =================
